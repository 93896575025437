import React, { useState, useEffect } from "react";
import { EncircledCrossExit, SendIcon } from "../../../assets/SVG/svg";
import styles from "./ChatInput.module.scss";
import PrimaryButton from "../Button/PrimaryButton";
import { SERVICES } from "../../../utils/ConstantSettings";
import { useDispatch, useSelector } from "react-redux";
import { setApiCount } from "../../../ReduxSlices/ApiCountSlice";
import Modal from "react-bootstrap/Modal";
import "./ChatInput.css"
import "./ChatInput.module.scss"

import { useNavigate } from "react-router-dom";

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { setCurrentPlan } from "../../../ReduxSlices/CurrentPlanSlice";
import axios from "axios";
import { useTracking } from "react-tracking";

const ChatInput = ({ 
  //killChatcall, 
  ...props }) => {
  const {trackEvent} = useTracking()
  const dispatch = useDispatch()
  const [placeholder, setPlaceholder] = useState(props?.text);

  const apiCounter = useSelector((state)=>state.apiCount)
  const questioner_filled = useSelector((state)=>state.questioner_filled)
  //console.log("uses left:",apiCounter)

  const headers = new Headers({
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json; charset=UTF-8'

  });


  
function disableGetFreeUsesBtn(){
  if (questioner_filled == true){
    return "grey"
  }
  else if (apiCounter == 0 && questioner_filled == true){
    return "grey"
  }
  else if (apiCounter == 0 && questioner_filled == false){
    return ""
  }
  else if (apiCounter > 0 && questioner_filled == false){
    return "grey"
  }
}

    // Enterprise modal pop up:
    const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);
    const handleShowEnterpriseModal = () => setShowEnterpriseModal(true);
    const handleCloseEnterpriseModal = () => setShowEnterpriseModal(false);

function dialogContentText(){
  if (questioner_filled == true){
    return `${t("form_already_filled")}`
  }

  else if (questioner_filled == false){
    return `${t("enjoying_simpla")}`
  }
  // else if (apiCounter > 0 && questioner_filled == false){
  //   return `${t("unexhauted_uses_left")}`
  // }
}

function discoverSimplaSendEmail(sendOption="email"){

  setShowEnterpriseModal(true)

  if (localStorage.getItem("simpla_enterprise_email_sent") == "false"){
      axios.post(`${SERVICES.apiBaseUrl}/api_usage/createPremiumRequest`, {notification_method: `${sendOption}`}, 
        {headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json; charset=UTF-8',

        }})
        .then((res)=>{
          let status;
          try {
            status = JSON.parse(res.data?.object)?.status
            localStorage.setItem("simpla_enterprise_email_sent", status )
          }
          catch(error){
            localStorage.setItem("simpla_enterprise_email_sent", true )
            trackEvent({
              event_type: "error", 
              event_name:"Failed to get/parse get-in-touch email status (response of API was not parsed)", 
              event_data:{
                error: `${error}`
              }
            })
          }

          if (res.data?.message === "Success" || res.data?.message === "success"){
            localStorage.setItem("simpla_enterprise_email_sent", true )
          }
        })
        .catch((error)=>{
          trackEvent({
            event_type: "error", 
            event_name:"Failed to send get-in-touch email request", 
            event_data:{
              error: `${error}`
            }
          })
        })
  }

  
}


const uselocation = useLocation()

const navigate = useNavigate()

const navigateToSurvey = () => {
  if (questioner_filled == false){
  
    // Access current pathname, search, and hash
    const { pathname, hash } = uselocation;

    // Full current URL

    
    let currentURL = "/"
    
    if(!`${pathname}${hash}`.includes("survey")){
      currentURL = `${pathname}${hash}`
    }
    
    //console.log('Current URL:', currentURL);

    localStorage.setItem("currentURL", currentURL)

    // navigate to survey
    //window.location.replace(`${window.location.origin.toString()}/survey`);
    navigate("/survey")
  }
  else {
    setShowModal(false)
    discoverSimplaSendEmail()
  }
};

    // want to use more of the module modal pop up:
    const [showModal, setShowModal] = useState(false);
    const handleModalShow = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false);

    const [stopResponse, setStopResponse] = useState(false)

  // useEffect(()=>{
  //   fetchApiCount()
  //   .then((res)=>{
  //     console.log("Api Count Request Successful")
  //   }).catch((error)=>{
  //     console.log("Failed to get Api Count")
  //   })
  // }, [])

  const [input, setInput] = useState("");
  const handleInputChange = (event) => {
    setInput(event.target.value);
  };
  const {t} = useTranslation()
  useEffect(() => {
    if (props.val == "none") {
      setInput("");
    }
    else{
      setInput(props.val);
    }
  }, [props.val]);
  useEffect(() => {
    if (props.loading  ) {
      setPlaceholder(`${t("simpla_typing")}...`);
    } else {
      setPlaceholder(props?.text);
    }
  }, [props.loading]);


  
  const [disableStatus, setDisableStatus] = useState(false);
  
  const checkInput = () => {
    // Regular expression to match empty strings, blank spaces, tabs, and new lines
    const isBlank = !input?.trim() || /^[\s\t\n]*$/.test(input);
    setDisableStatus(isBlank);
  };

  useEffect(() => {

    checkInput(); // Call the function to check input

  }, [input]); // Only re-run the effect if input changes


  return (
    <div className={`${styles.InputWrapper} chatInputWrapper`} style={{direction:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "ltr" : "ltr"}`}}>
      {/* <JoyrideComponent /> */}
      {/* <textarea
      style={{direction:`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "rtl" : "ltr"}`}}
        className={` form-control ${styles.Input} form-control-main`}
        list="datalistOptions"
        id="exampleDataList"
        rows="1"
        cols="50"
        placeholder={`${t("type_message")}`}
        value={input}
        onChange={handleInputChange}
        onKeyDown={(event) => {
          if (input !== "") {
            if (event.key === "Enter") {
              if (apiCounter == "0"){
                handleModalShow(true)
                //console.log("No More Uses Left!")
              }
              else{
              setInput("");
              props.onChange(input, "user");                
              }

            }
          }
        }}
        readOnly={props.loading || props.disableTextField}
      /> */}
<textarea
  style={{
    direction: localStorage.getItem('i18nextLng') === "ar" ? "rtl" : "ltr"
  }}
  className={`form-control ${styles.Input} form-control-main`}
  list="datalistOptions"
  id="exampleDataList"
  rows="1" // Start with a single row
  cols="50"
  placeholder={t("type_message")}
  value={input}
  onChange={handleInputChange}
  onKeyDown={(event) => {
    if (disableStatus === false) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault(); // Prevent default Enter behavior (form submission)
        if (apiCounter === "0") {
          handleModalShow(true);
          //console.log("No More Uses Left!")
        } else {
          setInput("");
          props.onChange(input, "user");
        }
      } else if (event.key === "Enter" && event.shiftKey) {
        // If Shift+Enter is pressed, add a newline without submitting the form
        event.preventDefault();
        setInput(input + "\n");
      }
    } else if (event.key === "Enter" && event.shiftKey) {
      // If Shift+Enter is pressed and input is empty, add a newline without submitting the form
      event.preventDefault();
      setInput("\n");
    }
  }}
  readOnly={props.loading || props.disableTextField}
/>

      {!props.loading && !props.disableTextField && (
        <PrimaryButton
          buttonText={<SendIcon />}
          style={{backgroundColor: "rgb(202, 202, 202)", position: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ?  "absolute !important" : ""}`, left: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "2.5%" : ""}`}}
          styleclass={
            input === ""
              ? `primarybtn ${styles.ButtonInput} ${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : ""}`
              : `primarybtn ${styles.ButtonInput} ${styles.active} ${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : ""}`
          }
          icon={undefined}
          hasIcon={false}
          size={undefined}
          type="submit"
          onClick={() => {
            if (apiCounter == "0"){
              handleModalShow(true)
              //console.log("No More Uses Left!")
            }
            else{
            setInput("");
            props.onChange(input, "user");                
            }
          }}

          disabled={ disableStatus || props.loading }
        />
      )}
      {props.loading  && (
        <button className={ localStorage.getItem("i18nextLng") === "ar" ? styles.StopGeneratingBtnAr : styles.StopGeneratingBtn } 
        onClick={()=>{
          setStopResponse(true);
          //console.log("stop generating btn clicked in chat input")
          //killChatcall(true)
        }}>
          {t("stop_response")}
        </button>
      )}

{/* uses exhausted dialog modal */}
<Dialog
  open={showModal}
  onClose={handleModalClose}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "285px",
      borderRadius: "15px",
      background: "#fdfbf5",

    }
  }}
>
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
      <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => setShowModal(false)} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit/>        
       </div>

      </div>


  </DialogTitle>
  <DialogContent>

    <div style={{padding:"4% 2%"}}>
    <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("want_more_btn")}
    </h6>
    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"25px", paddingTop:"1%"}}>
      {dialogContentText()}
    </p>    
    </div>

  </DialogContent>
  <DialogActions style={{padding:"0% 2% 7% 2%", margin: "0% 3%", gap:"10px"}}>
  <div onClick={navigateToSurvey} className={`more-uses-btn more-uses-dialog-btn ${styles["orange-bg-btn-transition"]}`} style={{  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Typography variant="p" className={`more-uses-btn-text ${styles["orange-bg-btn-text"]}`} style={{fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("get_more_uses")}</Typography>
    </div>

    <div className="more-uses-btn more-uses-dialog-btn" style={{ borderColor: `black`,  margin: "auto", display: "flex", justifyContent: "center", alignItems: "center", }} onClick={()=>{ setShowModal(false); discoverSimplaSendEmail()}}>
      <Typography variant="p" className="more-uses-btn-text" style={{color: `black`, fontSize: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "medium !important" :""}`}}>{t("simpla_enterprise")}</Typography>
    </div>
  </DialogActions>
</Dialog>

{/* We will be in touch modal */}
<Dialog
  open={showEnterpriseModal}
  onClose={handleCloseEnterpriseModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",


    }
  }}
>
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
    <div className="close-circle" 
        style={{ 
            position: 'absolute', 
            top: '11.5%', 
            right: localStorage.getItem('i18nextLng') === "ar" ? 'auto' : '2%', 
            left: localStorage.getItem('i18nextLng') === "ar" ? '2%' : 'auto', 
            transform: 'translateY(-50%)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' 
        }}>
        <CloseIcon 
            fontSize="normal" 
            onClick={() => setShowEnterpriseModal(false)} 
            style={{ margin: 0 }} 
        />
       </div>
  </DialogTitle>

  <DialogContent>

    <div style={{padding:"5%"}}>

    <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("in_touch_modal")}
    </h6>

    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"20px" }}>
      {t("in_touch_modal_description")}
    </p>

    </div>


  </DialogContent>


</Dialog>

    </div>
  );
};

export default ChatInput;