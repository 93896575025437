import React, { useContext,useEffect } from "react";
import { useSearchParams,useNavigate } from "react-router-dom"
import { getUserInfo } from '../../services/api'
import { AuthContext } from "../../utils/AuthContext";
import { Spinner } from "react-bootstrap";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";


// sample URL = https://app.simpla.ai/google-redirect?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE1MDc3NzA4LCJpYXQiOjE3MTM3ODE3MDgsImp0aSI6IjQzM2Y0ZWIxNWU0NDRkNWVhOTRjNzVhNTBmNGEzOWFjIiwidXNlcl9pZCI6MTMwfQ.CiB4IltmFakaCxyW3i4RJNCW6ZZr1gX0zB5eNZzLJWE&refresh_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcxNjM3MzcwOCwiaWF0IjoxNzEzNzgxNzA4LCJqdGkiOiI1MTYyZWRiNzkyZTQ0ZmMzYTZmZGY1ZTM1ZTBhYjE1NSIsInVzZXJfaWQiOjEzMH0.iW2RgNBENp8L9p4bmLylWWjFutWYiNn8juv0HDUpNIs

export const GoogleRedirect = () => {
  const { trackEvent } = useTracking();
  const {t} = useTranslation()

    const navigate= useNavigate()    

    /*OAuth provider (e.g., Google, Facebook) redirects the user back to your application with an 
      access token appended as a query parameter in the URL.*/
     const [queryparams]=useSearchParams();
     const access_token = queryparams.get('access_token');
     const { token, login, logout } = useContext(AuthContext);

    useEffect(()=>{
        //console.log("access token =", access_token)
        getUserDetail();
    },[access_token])  

const getUserDetail=async ()=>{

    if(access_token){
      localStorage.setItem('token',access_token);
        try{
        //console.log("getting user details")
        const response=await getUserInfo({access_token})
        let data=response?.data?.object
        // localStorage.setItem('token',access_token);
        localStorage.setItem('firstname',data.first_name);
        localStorage.setItem('lastname',data.last_name);
        localStorage.setItem("session_id", data.session_id);
        localStorage.setItem("profile", data?.has_profile);
        // google profile image can be assigned to redux state here
        //login(data);
        localStorage.setItem('onboarding', data.onboarding);

        if(data.show_disclaimer===false){
          localStorage.setItem('show-disclaimer',false);
        }
        if (data?.has_profile === true || data?.has_profile === "true") {
          trackEvent({event_type: "login", event_name: `User logged in using Google` , event_data: {profile_image: `${data.profile_image}`, email: `${data.email}`} })
          navigate("/");
        } else {
          trackEvent({event_type: "sign up", event_name: `User signed up using Google` , event_data: {profile_image: `${data.profile_image}`, email: `${data.email}`} })
          //navigate("/accountsetup");
          navigate("/paymentplan")


        }
        
        }catch(error){
          //console.log("error =", error)

            trackEvent({event_type: "error", event_name: `Google Authentication Failed` , event_data: {error: error} })
            logout();
            navigate('/login')

        }
    }
}
  return (
    <div> <Spinner animation="border" role="status">
    <span className="visually-hidden">{t("loading")}</span>
  </Spinner></div>
  )
}
