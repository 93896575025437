import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import "./dropdown.scss";
const DropdownCustom = ({ styleclass, text, itemText, itemicon,onSelect }) => {
  return (
    <Dropdown className="dropdown-wrapper">
      <Dropdown.Toggle
        className={`dropdown-custom ${styleclass}`}
        id="dropdown-basic"
      >
        {text}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {itemText
          ? itemText.map((item) => (
              <Dropdown.Item onClick={()=>{onSelect(item.id)}}  key={item?.id}>
                {item.label} {itemicon}
              </Dropdown.Item>
            ))
          : console.log("")}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default DropdownCustom;
