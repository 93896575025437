import React  from "react";
import Modal from "react-bootstrap/Modal";
import "./modalVedio.scss";
import Form from "react-bootstrap/Form";
import { PlayIcon } from "../../assets/SVG/svg";
import posterPicture from "../../assets/images/vedio1.png";
const ModalVedio = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      centered
      className="modal-vedio"
    >
      <Modal.Header closeButton>
        <Modal.Title>Onboarding Tutorial Videos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-control-wrapper">
          <Form.Control type="text" placeholder="Search" />
        </div>
        <div className="cards_wrapper">
          <div className="cardCustom">
            <div className="vedioContainer">
              <div className="vedioWrapper">
                <video
                  // src={Vedio}
                  className="vedioControl"
                  controls
                  width="100%"
                  poster={posterPicture}
                >
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            </div>
            <div className="card_footer">
              <div className="headingBox">
                <p className="vedioName">Account Setup Module</p>
                <p className="vedioDesc">
                  Watch how to setup account in simpla
                </p>
              </div>
              <span className="playBtn">
                <PlayIcon />
              </span>
            </div>
          </div>
          <div className="cardCustom">
            <div className="vedioContainer">
              <div className="vedioWrapper">
                <video 
                    className="vedioControl"
                    controls
                    width="100%"
                    poster={posterPicture}>
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            </div>
            <div className="card_footer">
              <div className="headingBox">
                <p className="vedioName">Account Setup Module</p>
                <p className="vedioDesc">
                  Watch how to setup account in simpla
                </p>
              </div>
              <span className="playBtn">
                <PlayIcon />
              </span>
            </div>
          </div>
          <div className="cardCustom">
            <div className="vedioContainer">
              <div className="vedioWrapper">
                <video 
                // src={Vedio}
                className="vedioControl"
                controls
                width="100%"
                poster={posterPicture}>
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            </div>
            <div className="card_footer">
              <div className="headingBox">
                <p className="vedioName">Account Setup Module</p>
                <p className="vedioDesc">
                  Watch how to setup account in simpla
                </p>
              </div>
              <span className="playBtn">
                <PlayIcon />
              </span>
            </div>
          </div>
          <div className="cardCustom">
            <div className="vedioContainer">
              <div className="vedioWrapper">
                <video
                //  src={Vedio}
                className="vedioControl"
                controls
                width="100%"
                poster={posterPicture}>
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            </div>
            <div className="card_footer">
              <div className="headingBox">
                <p className="vedioName">Account Setup Module</p>
                <p className="vedioDesc">
                  Watch how to setup account in simpla
                </p>
              </div>
              <span className="playBtn">
                <PlayIcon />
              </span>
            </div>
          </div>
          <div className="cardCustom">
            <div className="vedioContainer">
              <div className="vedioWrapper">
                <video
                //  src={Vedio}
                className="vedioControl"
                controls
                width="100%"
                poster={posterPicture}>
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            </div>
            <div className="card_footer">
              <div className="headingBox">
                <p className="vedioName">Account Setup Module</p>
                <p className="vedioDesc">
                  Watch how to setup account in simpla
                </p>
              </div>
              <span className="playBtn">
                <PlayIcon />
              </span>
            </div>
          </div>
          <div className="cardCustom">
            <div className="vedioContainer">
              <div className="vedioWrapper">
                <video 
                // src={Vedio}
                className="vedioControl"
                controls
                width="100%"
                poster={posterPicture}>
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            </div>
            <div className="card_footer">
              <div className="headingBox">
                <p className="vedioName">Account Setup Module</p>
                <p className="vedioDesc">
                  Watch how to setup account in simpla
                </p>
              </div>
              <span className="playBtn">
                <PlayIcon />
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalVedio;
