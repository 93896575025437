import React, { useEffect, useRef, useState } from "react";
import {
  HistoryIcon,
  HelpIcon,
  PlusIconDark,
  LogoIcon,
} from "../../assets/SVG/svg";
import Collapse from "react-bootstrap/Collapse";
import styles from "./Sidebar.module.scss";
import ButtonRounded from "../common/Button/ButtonRounded";
import HistoryModal from "../history/history";
import VerticalTabs from "../VerticalTabs";
import VerticalTabsHelp from "../VerticalTabsHelp";

import Drawer from '@mui/material/Drawer';
import { Button, Box } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import "./Sidebar.css"

// redux
import { useSelector, useDispatch } from "react-redux";
import { setDisplaySidebar } from "../../ReduxSlices/SidebarSlice";
import { disableAllChatMode } from "../../ReduxSlices/ChatModeSlice";
import { useTranslation } from "react-i18next";

const Sidebar = (props) => {
  const { t } = useTranslation()
  const [history, setHistory] = useState(false);
  const [open, setOpen] = useState(false);
  const [HelpOpen, setHelpOpen] = useState(false);


  const dispatch = useDispatch()
  const displayBar = useSelector((state) => state.displaySidebar.value)

  // const sidebarRef = useRef(null);


  // const handleOutsideClick = (event) => {
  //   if ( sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //     //dispatch(setDisplaySidebar('none'))
  //   }
  // };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600 && displayBar === 'none') {
        setHistory(false)
      }
    };

    window.addEventListener('resize', handleResize);

  }, [displayBar]);

  //   useEffect(() => {
  //   if (displayBar === 'block') {
  //     window.addEventListener('click', handleOutsideClick);
  //   }

  //   return () => {
  //     window.removeEventListener('click', handleOutsideClick);
  //   };
  // }, [displayBar]);
  const borderRadiusStyle = localStorage.getItem('i18nextLng')?.toString() === "ar" ? "0px 0px 0px 90px" : "0px 0px 90px 0px";


  return (

    <div>


      {/* 
    <div style={{margin:'50px 0px 0px 10px'}}>
          <MenuIcon fontSize="large" onClick={handleDisplay}/>
    </div> */}

      <div className="desktop-sidebar">
      <div
        className={`${styles.sidenavWrapper} ${props.styleClass} ${history ? styles.noBorderRadius : ''}`}
        style={{ borderRadius: borderRadiusStyle }}
      >
          <div className={`${styles.sidebarMenuWrapper}  ${history ? 'sideMenuWrapperShadow' : ''}`}>
            <div className={styles.topSection}>
              <div className={styles.ItemList}>
                <div
                  className={`${styles.collapseWraper} ${open ? "collapseWraper" : null
                    } `}
                >
                  <ButtonRounded
                    buttonIcon={<PlusIconDark />}
                    styleclass="btn-rounded-lg add-btn add-btn-desktop tooltip btn-sidenav-active"
                    btnBorder="border-color"
                    onClick={() => { setHelpOpen(false); setOpen(!open) }}
                    tooltipText={`${t("new_chat")}`}
                  />
                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      <VerticalTabs />
                    </div>
                  </Collapse>
                </div>
                <hr />
                <ButtonRounded
                  buttonIcon={<HistoryIcon />}
                  styleclass={`btn-rounded-lg bg-color-sidenav border-0 btn-history-desktop btn-history  tooltip ${history ? "history-active" : null
                    }`}
                  btnBorder="none"
                  tooltipText={`${t("history")}`}
                  onClick={() => {
                    setHistory(true);
                    props?.refetch();
                  }}
                />
                <div style={{ paddingBottom: "50px" }}>
                  <div
                    className={`${styles.collapseWraper} ${setOpen ? "collapseWraper" : null
                      } `}
                  >
                    <ButtonRounded
                      buttonIcon={<HelpIcon />}
                      styleclass={` tooltip btn-rounded-lg bg-color-sidenav border-0 btn-help-desktop btn-help ${HelpOpen ? "btn-sidenav-active btn-help-active" : null
                        }`}
                      btnBorder="none"
                      onClick={() => { setOpen(false); setHelpOpen(!HelpOpen) }}
                      tooltipText={`${t("help")}`}
                    />
                    <Collapse in={HelpOpen}>
                      <div id="example-collapse-text">
                        <VerticalTabsHelp
                          productTour={props?.handleCloseOnboarding}
                        />
                      </div>
                    </Collapse>
                  </div>
                </div>

              </div>
            </div>
            {/* <div className={styles.logo}>
          <LogoIcon />
        </div> */}
          </div>
          {history && (
            <HistoryModal
              onClose={() => {
                setHistory(false);
                props?.filterSetter("All");
              }}
              refetch={props?.refetch}
              data={props?.chatroomsdata}
              loading={props?.loading}
              filterSetter={props?.filterSetter}
              filter={props?.filter}
              searchSetter={props?.searchSetter}
              search={props?.search}
            />
          )}
        </div>
      </div>

      {/* mobile side bar view */}

      <div style={{ display: `${displayBar}`, }}>
        <div className={`${styles.sidenavWrapper} ${props.styleClass}`} >
          <div className={`${styles.sidebarMenuWrapper} ${history ? 'sideMenuWrapperShadow' : ''}`}>
            <div className={styles.topSection}>
              <div className={styles.ItemList} style={{paddingBottom:"50px"}}>
                <div
                  className={`${styles.collapseWraper} ${open ? "collapseWraper" : null
                    } `}
                >
                  <ButtonRounded
                    buttonIcon={<PlusIconDark />}
                    styleclass="btn-rounded-lg add-btn add-btn-mobile tooltip btn-sidenav-active"
                    btnBorder="border-color"
                    onClick={() => { setHelpOpen(false); setOpen(!open) }}
                    tooltipText={`${t("new_chat")}`}
                  />
                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      <VerticalTabs />
                    </div>
                  </Collapse>
                </div>
                <hr />
                <ButtonRounded
              buttonIcon={<HistoryIcon />}
              styleclass={`btn-rounded-lg bg-color-sidenav border-0 btn-history-mobile btn-history tooltip ${
                history ? "history-active" : null
              }`}
              btnBorder="none"
              tooltipText={`${t("history")}`}
              onClick={() => {
                setHistory(true);
                props?.refetch();
              }}
            />
                <div
                  className={`${styles.collapseWraper} ${setOpen ? "collapseWraper" : null
                    } `}
                >
                  <ButtonRounded
                    buttonIcon={<HelpIcon />}
                    styleclass={` tooltip btn-rounded-lg bg-color-sidenav border-0 btn-help-mobile btn-help ${HelpOpen ? "btn-sidenav-active btn-help-active" : null
                      }`}
                    btnBorder="none"
                    onClick={() => { setOpen(false); setHelpOpen(!HelpOpen) }}
                    tooltipText={`${t("help")}`}
                  />
                  <Collapse in={HelpOpen}>
                    <div id="example-collapse-text">
                      <VerticalTabsHelp
                        productTour={props?.handleCloseOnboarding}
                      />
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
            {/* <div className={styles.logo}>
          <LogoIcon />
        </div> */}
          </div>
          {history && (
            <HistoryModal
              onClose={() => {
                setHistory(false);
                props?.filterSetter("All");
              }}
              refetch={props?.refetch}
              data={props?.chatroomsdata}
              loading={props?.loading}
              filterSetter={props?.filterSetter}
              filter={props?.filter}
              searchSetter={props?.searchSetter}
              search={props?.search}
            />
          )}
        </div>
      </div>



    </div>





  );
};

export default Sidebar;
