import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";

// const initialState: CounterState = {value:0} // annotations are only for TypeScript
// const initialValue = {
//     remaining_credits: "0"
// }
const signOffCreditsSlice = createSlice({
    name:'signOffCredits',
    initialState: "0",
    reducers: {
        setSignOffCredits: (state, action) => {
            return action.payload;
        }
    }
})

export const {setSignOffCredits} = signOffCreditsSlice.actions;
export default signOffCreditsSlice.reducer