import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import "./dataresults.scss";
import CheckboxCustom from "../../components/common/checkbox/checkbox";
import SecondaryButton from "../../components/common/Button/ButtonSecondary";
import {
  BackIcon,
  CheckedTrueDark,
  DownloadIcon,
  TrashIcon,
  LineItemsIcon,
} from "../../assets/SVG/svg";
import ModalCustom from "../../components/common/ModalCustom/modalCustom";
import AlertCustom from "../../components/common/Alert/alert";
import DropdownCustom from "../../components/common/DropDown";
import { columnNames, processcolumnNames,getCurrentDateTime } from "../../utils/helper";
import {
  editInvoice,
  deleteInvoice,
  downloadInvoice,
  processInvoice,
  submitInvoice,
} from "../../services/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { LineItems } from "./lineItems";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
const DataResults = (props) => { 
  
  const {t} = useTranslation()

  const {trackEvent} = useTracking()
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [lineItems, setLineItems] = useState(false);
  const [alert, setAlert] = useState(false);
  const [data, setData] = useState(props?.tableData);
  const [checkedIds, setCheckedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [column, setColumn] = useState(
    props?.view ? processcolumnNames : columnNames
  );
  useEffect(() => {
    setColumn(props?.view ? processcolumnNames : columnNames);
  }, [props?.view]);
  const [progress, setProgress] = useState(50);
  const patchInvoice = useMutation(editInvoice);
  const dellInvoice = useMutation(deleteInvoice);
  const submitInv = useMutation(submitInvoice);
  const downloadInv = useMutation(downloadInvoice);
  const { refetch: process } = useQuery(
    [localStorage.getItem("profileid"), props?.batch, "process"],
    processInvoice,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
      onSuccess: (data) => {
        setData(data?.data?.object?.data);
        setProgress(100);
        setModal(false);
        setAlert(true);
      },
    }
  );
  useEffect(() => {
    setData(props?.tableData);
  }, [props?.tableData]);
  useEffect(() => {
    setAlert(props?.view);
  }, [props?.view]);
  const submitCall = (val) => {
    trackEvent({event_type: "click", event_name: "user submitted the processed invoices in Process module", event_data: {action: "submit button in Process Module was clicked", submit_type: `${val}`}})
    submitInv.mutate(
      {
        profile: localStorage.getItem("profileid"),
        batch: props?.batch,
        type: val,
      },

      {
        onSuccess: (data, variables, context) => {
          setModal(true);
        },
        onError: (error, variables, context) => {
          trackEvent({
            event_type: "error", 
            event_name:"Failed to process invoice", 
            event_data:{
              error: `${error}`,
              submit_type: `${val}`
            }
          })
        },
      }
    );
  };
  const downloadCall = (val) => {
   // console.log("download invoice",JSON.stringify(props))
    trackEvent({event_type: "click", event_name: "user downloaded the invoices in Process module", event_data: {action: "download button in Process Module was clicked"}})
    downloadInv.mutate(
      {
        profile: localStorage.getItem("profileid"),
        batch: props?.batch,
        type: val,
      },

      {
        onSuccess: (data, variables, context) => {
          const blob = new Blob([data.data], {
            type: "application/octet-stream",
          });
          const downloadUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = `simpla-process-${getCurrentDateTime()}.csv`;
          link.click();
          URL.revokeObjectURL(downloadUrl);
        },
        onError: (error, variables, context) => {
          trackEvent({
            event_type: "error", 
            event_name:"Failed to export/download invoice data", 
            event_data:{
              error: `${error}`,
              batch: props?.batch,
              type: val,

            }
          })
        },
      }
    );
  };
  const editInvoiceCall = (colId, newValue, id) => {
    patchInvoice.mutate(
      {
        val: { [colId]: newValue },
        profile: localStorage.getItem("profileid"),
        batch: props?.batch,
        id: id,
      },

      {
        onSuccess: (data, variables, context) => {},
        onError: (error, variables, context) => {
          trackEvent({
            event_type: "error", 
            event_name:"Failed to edit invoice", 
            event_data:{
              error: `${error}`
            }
          })
        },
      }
    );
  };
  const dellInvoiceCall = (inv) => {
    dellInvoice.mutate(
      {
        val: { invoice_ids: inv },
        profile: localStorage.getItem("profileid"),
        batch: props?.batch,
      },

      {
        onSuccess: (dataa, variables, context) => {
          const updatedData = data.filter(
            (item) => !inv.includes(item.id.value)
          );
          setData(updatedData);
          setCheckedIds([]);
        },
        onError: (error, variables, context) => {},
      }
    );
  };
  const handleCellEdit = (rowIndex, colId, newValue, id) => {
    const updatedData = data.map((rowData, i) => {
      if (i === rowIndex) {
        return { ...rowData, [colId]: newValue };
      }
      return rowData;
    });
    editInvoiceCall(colId, newValue, id);
    setData(updatedData);
  };
  const handleRowCheck = (rowIndex) => {
    setCheckedIds((prevCheckedIds) => {
      const newCheckedIds = [...prevCheckedIds];
      if (newCheckedIds.includes(rowIndex)) {
        // Remove the ID if already checked
        newCheckedIds.splice(newCheckedIds.indexOf(rowIndex), 1);
        setSelectAll(false);
      } else {
        // Add the ID if not checked
        newCheckedIds.push(rowIndex);
        if (newCheckedIds.length === data.length) {
          setSelectAll(true);
        }
      }
      return newCheckedIds;
    });
  };
  const handleSelectAll = () => {
    if (selectAll) {
      setCheckedIds([]);
    } else {
      const allIds = data.map((_, index) => index);
      setCheckedIds(allIds);
    }
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };
  const multipleDell = () => {
    const matchingIds = checkedIds.map((index) => data[index]?.id?.value);
    dellInvoiceCall(matchingIds);
  };
  const [selectedRowIndex, setSelectedRowIndex] = useState(false);
  const handleLineItemsClick = (rowIndex) => {
    setLineItems(true);
    setSelectedRowIndex(rowIndex); // Store the current rowIndex in state
  };
  function handleLineItemChange(updatedValue, lineItemIndex, row, keyName) {
    // Create a copy of the data array to avoid mutating state directly
    const updatedData = [...data];

    // Update the specific value in the line item
    updatedData[row].line_items[lineItemIndex][keyName].value = updatedValue;

    // Set the updated data array in the state
    setData(updatedData);
  }
  function removeLineItem(lineItemIndex, row) {
    // Create a copy of the data array to avoid mutating state directly
    const updatedData = [...data];

    // Remove the line item at the specified index
    updatedData[row].line_items.splice(lineItemIndex, 1);

    // Set the updated data array in the state
    setData(updatedData);


  }
  return (
    <div className="data-results-container">
      {props?.loading && (
        <div className="overlay">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t("loading")}</span>
          </Spinner>
        </div>
      )}
      <div className="title-bar">
        <div className="back-btn" style={{transform: `${localStorage.getItem('i18nextLng') == 'ar' ? "rotate(180deg)" : ""}`}}>
          <BackIcon />
        </div>
        <span className="title-bar-text">
          {alert ? `${t("processed_data")}` : `${t("imported_results")}`}
        </span>
      </div>
      {alert && (
        <AlertCustom
          variant="success"
          text={`${t("process_success")}`}
        />
      )}
      <div className="imported-wrapper">
        <Table responsive className="data-table">
          <thead>
            <tr>
              {!alert && (
                <th className="sticky-column">
                  <CheckboxCustom
                    type="checkbox"
                    label=""
                    name="group1"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
              )}
              {column?.map((val) => (
                <th key={val?.id}>{ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? val?.nameArabic : val?.name}</th>
              ))}
              <th className="sticky-column-right sticky-column-2"> </th>
              {!alert && (
                <th
                  className="sticky-column-right"
                  onClick={() => {
                    if (!alert && checkedIds.length !== 0) {
                      multipleDell();
                    }
                  }}
                >
                  <TrashIcon />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((rowData, rowIndex) => (
              <>
                <tr key={rowIndex}>
                  {!alert && (
                    <td className="sticky-column">
                      <CheckboxCustom
                        type="checkbox"
                        label=""
                        name={`group${rowIndex}`}
                        checked={checkedIds.includes(rowIndex) || selectAll}
                        onChange={() => handleRowCheck(rowIndex)}
                      />
                    </td>
                  )}
                  {column?.map((col) => (
                    <td
                      key={col.id}
                      className={rowData[col.id]?.highlight && "selected-red"}
                    >
                      <input
                        className={
                          rowData[col.id]?.value === "Pass"
                            ? "form-control table-form-control pass-event-key"
                            : rowData[col.id]?.value === "Fail"
                            ? "form-control table-form-control fail-event-key"
                            : "form-control table-form-control"
                        }
                        type="text"
                        value={rowData[col.id]?.value}
                        title={rowData[col.id]?.value}
                        onChange={(e) =>
                          handleCellEdit(
                            rowIndex,
                            col.id,
                            e.target.value,
                            rowData?.id?.value
                          )
                        }
                        readOnly={col?.readOnly || !props?.edittable}
                      />
                    </td>
                  ))}
                  <td
                    className="sticky-column-right sticky-column-2"
                    onClick={() => {
                      handleLineItemsClick(rowIndex);
                    }}
                  >
                    <LineItemsIcon />
                  </td>
                  {!alert && (
                    <td
                      className="sticky-column-right"
                      onClick={() => {
                        if (!alert) {
                          dellInvoiceCall([rowData?.id?.value]);
                        }
                      }}
                    >
                      <TrashIcon />
                    </td>
                  )}
                </tr>

                {lineItems && rowIndex === selectedRowIndex && (
                  <LineItems
                    onClose={() => {
                      setLineItems(false);
                      setSelectedRowIndex(null); // Clear the selected row index
                    }}
                    lineitems={data[rowIndex]?.line_items}
                    alert={alert}
                    batch={props?.batch}
                    updateLineItemValue={handleLineItemChange}
                    row={rowIndex}
                    idinv={rowData?.id?.value}
                    removeLineItem={removeLineItem}
                    edittable={props?.edittable}
                  />
                )}
              </>
            ))}
          </tbody>
        </Table>
        <div className="table-footer">
          {!alert ? (
            <div className="table-footer-container">
              <SecondaryButton
                buttonText={`${t("download")}`}
                styleclass={"btn-transparent"}
                icon={undefined}
                hasIcon={false}
                size={undefined}
                type="submit"
                onClick={() => {
                  downloadCall("All");
                }}
              />
              <SecondaryButton
                buttonText={`${t("process")}`}
                styleclass={"btn-process"}
                icon={undefined}
                hasIcon={false}
                size={undefined}
                type="submit"
                onClick={() => {
                  trackEvent({event_type: "click", event_name: "user processed the uploaded invoices in Process module", event_data: {action: "process button in Process Module was clicked"}})
                  setModal(true);
                  process();
                  setColumn(processcolumnNames);
                }}
              />
            </div>
          ) : (
            <>
              {" "}
              <DropdownCustom
                styleclass="submit-dropdown"
                text={`${t("download")}`}
                itemText={[
                  { id: "All", label: `${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "كل المعلومات" : "All data"}` },
                  { id: "Pass", label: localStorage.getItem('i18nextLng') === "ar" ? "بيانات النجاح" : "Pass data" },
                  { id: "Fail", label: localStorage.getItem('i18nextLng') === "ar" ? "بيانات الفشل" : "Fail data" }
                ]}
                itemicon={<DownloadIcon />}
                onSelect={(item) => {
                
                  downloadCall(item);
                }}
              />
              {props?.edittable && (
                <DropdownCustom
                  styleclass="download-dropdown"
                  text={`${t("submit")}`}
                  itemText={[
                    { id: "Pass", label: localStorage.getItem('i18nextLng') === "ar" ? "تقديم بيانات النجاح فقط" : "Submit Pass data only" },
                    { id: "All", label: localStorage.getItem('i18nextLng') === "ar" ? "تقديم كافة البيانات" : "Submit all data" }
                  ]}
                  onSelect={(item) => {
                    submitCall(item);
                  }}
                />
              )}{" "}
            </>
          )}
          {modal && (
            <ModalCustom
              onClose={() => {
                setModal(false);
                props?.resetState();
                navigate("/invoice");
              }}
              progress={progress}
              type={alert}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default DataResults;
