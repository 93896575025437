import React from "react";
import Form from "react-bootstrap/Form";
import { matchMaker } from "../../../locales/AnalyseTranslationModule/AnalyseTranslationModule";
const AnalyseFormSelectRounded = ({
  label,
  options,
  value,
  keyVal,
  onChange,
  disabled,
  styleclass,
  text,
  locale
}) => {
  
  //console.log("AnalyseFormSelectRounded - locale", locale)
  return (<>
    <label className="form-label">{label}</label>
    {/* <Form.Select
      aria-label={label}
      className={`form-select-rounded ${styleclass} `}
      value={disabled ? "" : value}   
      onChange={(e)=>onChange(keyVal,e.target.value,e.target.options[e.target.selectedIndex].getAttribute('data-value'))}
      disabled={disabled}
    >
  {value === ""    && <option value="">{text}</option>}
 
      {options?.map((item) => (
        <option value={item.id} keyy={item.id} disabled={item?.disable}  data-value={item.value} key={item?.id}>
          {item.valueArabic}
        </option>
      ))}
    </Form.Select> */}

<Form.Select
        aria-label={label}
        className={`form-select-rounded ${styleclass} `}
        value={disabled ? "" : value}
        // onChange={(e) => {
        //   const selectedValue = e.target.value;
        //   const selectedOption = locale ===  "ar" ? options.find(option => option.valueArabic === selectedValue) : options.find(option => option.value === selectedValue);
        //   console.log("selectedValue:", selectedValue)
        //   console.log("selectedOption:", selectedOption)

        //   onChange(keyVal, selectedValue, selectedOption?.value);
        // }}
        onChange={(e)=>onChange(keyVal,e.target.value,e.target.options[e.target.selectedIndex].getAttribute('data-value'))}

        disabled={disabled}
      >
        {value === "" && <option value="">{text}</option>}
        {options?.map((item) => (
          <option
            value={item.id}
            keyy={item.id}
            disabled={item?.disable}
            data-value={item.value}
            key={item?.id}
          >
            {/* { locale && locale === "ar" ? item.valueArabic : item.value} */}

            {matchMaker(`${item.value}`, `${locale}`)}

          </option>
        ))}
      </Form.Select>


    
    </>
  );
};
export default AnalyseFormSelectRounded;
